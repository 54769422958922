<template>
	<div class="main-content">
		<ktv-breadcrumb title="Detail Agronomist" page="List" folder="Agronomist" />
		<div>
			<ktv-back @click="backToList()" />

			<b-row class="mt-3">
				<b-col cols="6">
					<ktv-accor title="Agronomist Data" name="accor-agronomist-data">
						<template #content>
							<b-tabs active-nav-item-class="nav nav-tabs">
								<b-tab title="Basic Data">
									<ktv-head-form title="Agronomist Data" />
									<b-row>
										<b-col>
											<vcl-list v-show="loading" class="mt-4" />
											<vcl-list v-show="loading" class="mt-4" />
											<b-row v-show="!loading" style="margin-top: 15px">
												<b-form-group class="col-md-12 mt-2">
													<b-row>
														<b-col class="col-4">
															<img src="https://devel.cocoatrace.com/api//images/Photo/default-user.png" style="height:140px;width:150px;float:left;">
															<!--															<b-img v-bind="mainProps" rounded alt="Rounded image"></b-img>-->
														</b-col>
														<b-col class="col-8">
															<span>Image file size should be less than 10MB.</span><br>
															<span>Supported formats: jpeg,jpg,png.</span><br>
															<span>Maximum image quality is 640px X 640px.</span>
														</b-col>
													</b-row>
												</b-form-group>
												<b-form-group class="col-md-12 mt-2" label="Staff ID">
													<b-form-input v-model="detailItems.StaffID" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-12 mt-2" label="Full Name">
													<b-form-input v-model="detailItems.name" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-12 mt-2" label="Birthdate">
													<b-form-input
														v-model="detailItems.BirthDate"
														name="Birthdate"
														:state="validateState('Birthdate')"
														type="date"
														disabled
													/>
													<b-form-invalid-feedback>
														{{ veeErrors.first("Birthdate") }}
													</b-form-invalid-feedback>
												</b-form-group>
												<b-form-group class="col-md-12 mt-2" label="Gender" disabled>
													<b-form-radio-group
														v-model="detailItems.Gender"

														name="Gender"
														:state="validateState('Gender')"
														class="mt-1"
														:options="options2"
													/>
													<b-form-invalid-feedback>
														{{ veeErrors.first("Gender") }}
													</b-form-invalid-feedback>
												</b-form-group>
												
												<b-form-group class="col-md-12 mt-2" label="Last Education" disabled>
													<b-form-select

														name="Institution NamLast Education"
														:state="validateState('Institution NamLast Educatione')"
														type="text"
													/>
													<b-form-invalid-feedback>
														{{ veeErrors.first("Last Education") }}
													</b-form-invalid-feedback>
												</b-form-group>
												<b-form-group class="col-md-12 mt-2" label="Partner">
													<b-form-input v-model="detailItems.name" type="text" readonly />
												</b-form-group>
											</b-row>

											<div class="mt-3" />
										</b-col>

										<b-col>
											<ktv-head-form title="Communication and Media" style="margin-top: 20px" />
											<vcl-list v-show="loading" class="mt-4" />
											<b-row v-show="!loading">
												<b-form-group class="col-md-12 mt-2" label="Phone Type" disabled>
													<b-form-select

														name="Phone Type"
														:state="validateState('Phone Type')"
														type="text"
													/>
													<b-form-invalid-feedback>
														{{ veeErrors.first("Phone Type") }}
													</b-form-invalid-feedback>
												</b-form-group>
												<b-form-group class="col-md-12 mt-2" label="Handphone Number" disabled>
													<b-form-input

														name="Handphone Number"
														:state="validateState('Number')"
														maxlength="16"
														type="text"
													/>
													<b-form-invalid-feedback>
														{{ veeErrors.first("Number") }}
													</b-form-invalid-feedback>
												</b-form-group>
												<b-form-group class="col-md-12 mt-2" label="Email" disabled>
													<b-form-input 
														v-model="detailItems.email"
														name="Email"
														:state="validateState('Email')"
														type="email"
													/>
													<b-form-invalid-feedback>
														{{ veeErrors.first("Email") }}
													</b-form-invalid-feedback>
												</b-form-group>
											</b-row>
											<div class="mt-4" />
										</b-col>
									</b-row>
									<ktv-head-form title="Address and Location" />
									<b-row>
										<vcl-list v-show="loading" class="mt-4" />
										<b-col v-show="!loading">
											<b-form-group class="col-md-12 mt-2" label="Location">
												<b-form-textarea
													disabled
												/>
												<b-form-invalid-feedback>
													{{ veeErrors.first("Location") }}
												</b-form-invalid-feedback>
											</b-form-group>
										</b-col>
										<b-col v-show="!loading">
											<b-form-group class="col-md-12 mt-2" label="Address">
												<b-form-textarea v-model="detailItems.Address"
													disabled
												/>
												<b-form-invalid-feedback>
													{{ veeErrors.first("Address") }}
												</b-form-invalid-feedback>
											</b-form-group>
										</b-col>
									</b-row>
								</b-tab>

								<b-tab title="Detail Data">
									<ktv-head-form title="Detail" />
									<vcl-list v-show="loading" class="mt-4" />
									<div v-if="bankData.length > 0">
										<b-row v-for="(x, key) in bankData" v-show="!loading" :key="key">
											<b-col>
												<b-form-group class="col-md-12 mt-2" label="Bank Account">
													<b-form-input v-model="x.AccountNumber" type="text" />
												</b-form-group>
												<b-form-group class="col-md-12 mt-2" label="Bank Account Holder">
													<b-form-input v-model="x.AccountHolderName" />
												</b-form-group>
												<b-form-group class="col-md-12 mt-2" label="Bank Name">
													<b-form-input v-model="x.BankName" />
												</b-form-group>	
												<b-form-group class="col-md-12 mt-2" label="Bank Account Number">
													<b-form-input />
												</b-form-group>												
												<hr>
											</b-col>
										</b-row>
									</div>
									<div v-else>
										<b-row>
											<b-col>
												<b-form-group class="col-md-12 mt-2" label="Bank Account">
													<b-form-input type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-12 mt-2" label="Bank Account Holder">
													<b-form-input readonly />
												</b-form-group>
												<b-form-group class="col-md-12 mt-2" label="Bank Name">
													<b-form-input readonly />
												</b-form-group>		
												<b-form-group class="col-md-12 mt-2" label="Bank Account Number">
													<b-form-input readonly />
												</b-form-group>																						
												<hr>
											</b-col>
										</b-row>
									</div>
								</b-tab>
							</b-tabs>
						</template>
					</ktv-accor>
				</b-col>
				<b-col>
					<ktv-accor title="Farmer Assignment" name="farmer-assignment-accor" :footer="opsiDisplay !== 'View'">
						<template #content>
							<b-button v-if="opsiDisplay !== 'View'" variant="success" @click="openFormRole('add')">+ Add</b-button>	
							<br><br>
							<table class="table table-hover ">
								<thead class="thead">
									<tr class="">
										<th v-if="opsiDisplay !== 'View'">Action</th>
										<th>ID</th>
										<th>Start Date</th>
										<th>End Date</th>
										<th>Type Role</th>
										<th>Partner (Private)</th>
										<th>Farmer (Self Assignment)</th>
										<th>FA Status</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(a, key) in rows" :key="key">
										<td v-if="opsiDisplay !== 'View'">
											<b-dropdown id="dropdown-1" text="" variant="link" class="m-md-2">
												<b-dropdown-item @click="findData(a, 'edit')"><i class="fa fa-pencil" aria-hidden="true"></i> &nbsp; Update</b-dropdown-item>
											</b-dropdown>
										</td>
										<td>{{ a.FCFieldAgentMappingID }}</td>
										<td>{{ formatDate(a.StartFrom) }}</td>
										<td>{{ formatDate(a.StartTo) }}</td>
										<td>{{ a.Type }}</td>
										<td>
											<div v-if="a.Partner !== null ">
												<div v-for="(p, idx) in a.Partner" :key="idx">
													{{ p.text }},
												</div>
											</div>
											<div v-if="a.Partner === null">
												-
											</div>
										</td>
										<td>
											<div v-if="a.Farmer !== null ">
												<div v-for="(f, idx) in a.Farmer" :key="idx">
													{{ f.text }},
												</div>
											</div>
											<div v-if="a.Farmer === null">
												-
											</div>
										</td>
										<td>
											<div v-if="a.Status === 'ACTIVE'">
												<b-button variant="success" size="sm" style="width:100%; font-size:11px">ACTIVE</b-button>	
											</div>
											<div v-else>
												<b-button variant="danger" style="width:100%; font-size:11px">INACTIVE</b-button>	
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</template>
					</ktv-accor>
				</b-col>
			</b-row>
		</div>

		<ktv-modal v-model="formRoleModal" size="lg" title="Add Role" no-close-on-backdrop no-close-on-esc @close="closeFormRole">
			<template #content>
				<b-card style="background: #f5f5f5;">
					<label><b>Select Options :</b></label>
					<b-form-radio-group v-slot="{ ariaDescribedby }" label="Select Options : ">
						<b-form-radio v-model="selectedOptions" :aria-describedby="ariaDescribedby" name="some-radios" value="1">Manual</b-form-radio>
						<b-form-radio v-model="selectedOptions" :aria-describedby="ariaDescribedby" name="some-radios" value="2">Bulk</b-form-radio>
					</b-form-radio-group>
				</b-card>
				<hr>
				<!-- MANUAL -->
				<template v-if="selectedOptions == 1">
					<b-row>
						<b-col>
							<div class="form-group row mt-3">
								<label class="col-sm-3">Status Type :<span class="text-danger"> *</span></label>
								<b-form-radio-group
									v-model="formRole.Type"
									class="col-sm-9"
									:options="optionType"
									data-vv-scope="role"
								>
								</b-form-radio-group>
							</div>
						</b-col>
					</b-row>
					<hr>
					<template v-if="formRole.Type == 'PUBLIC'">
						<vcl-list v-show="loadingDetail" />
						<b-form v-show="!loadingDetail">
							<b-row>
								<b-col>
									<b-form-group class="col-md-12 mt-2">
										<b-form-input
											v-model="EntityId"
											name="EntityId"
											value="1161741"
											hidden
										/>
									</b-form-group>
									<div class="form-group row mt-3">
										<label class="col-sm-2">Choose region :<span class="text-danger"> *</span></label>
										<b-form-radio-group
											v-model="LocationType"
											v-validate="'required'"
											class="col-sm-9"
											description=""
											:options="optionRegion"
											name="region"
											:state="validateState('region')"									
										>
											<br>
											<small class="text-danger">
												{{ veeErrors.first('region') }}
											</small>
										</b-form-radio-group>
									</div>
									<div class="form-group row mt-3">
										<label class="col-sm-2">Start Date :<span class="text-danger"> *</span></label>
										<b-form-group id="start_date" class="col-sm-3" description="">
											<b-form-input
												id="StartFrom"
												v-model="StartFrom"
												v-validate="'required'"
												name="StartFrom"
												:state="validateState('StartFrom')"
												data-vv-as="Start Date"
												type="date"
											/>
											<b-form-invalid-feedback>
												{{ veeErrors.first('StartFrom') }}
											</b-form-invalid-feedback>
										</b-form-group>
									</div>
									<div class="form-group row mt-3">
										<label class="col-sm-2">End Date :<span class="text-danger"> *</span></label>
										<b-form-group id="end_date" class="col-sm-3" description="">
											<b-form-input
												id="StartTo"
												v-model="StartTo"
												v-validate="'required'"
												name="StartTo"
												:state="validateState('StartTo')"
												data-vv-as="End Date"
												type="date"
											/>
											<b-form-invalid-feedback>
												{{ veeErrors.first('StartTo') }}
											</b-form-invalid-feedback>
										</b-form-group>									
									</div>
									<div class="form-group row mt-3">
										<label class="col-sm-2">Status :<span class="text-danger"> *</span></label>
										<b-form-radio-group
											id="Status"
											v-model="Status"
											v-validate="'required'"
											:options="optionStatus"
											name="Status"
											class="col-sm-9"
											:state="validateState('Status')"
										>
											<br>
											<small class="text-danger">
												{{ veeErrors.first('Status') }}
											</small>
										</b-form-radio-group>
									</div>
								</b-col>
							</b-row>
						</b-form>
					</template>

					<template v-if="formRole.Type == 'PRIVATE'">
						<b-row>
							<b-col>
								<div class="form-group row mt-3">
									<label class="col-sm-2">Partner Access :<span class="text-danger"> *</span>&nbsp;&nbsp;&nbsp;<div v-if="loadingGroups" class="spinner sm spinner-primary" style="height:15px;width:15px" /></label>
									<v-multiselect-listbox
										v-if="optionGroups.length"
										v-model="Partner"
										v-validate="'required'"
										class="col-sm-9"
										style="width: 100%"
										:options="optionGroups"
										:reduce-display-property="(option) => option.text"
										:reduce-value-property="(option) => option.value"
										no-options-found-text="No data found"
										no-selected-options-found-text="No selected data found"
										name="partner"
										:state="validateState('partner')"
										data-vv-as="partner"
									/>
									<v-multiselect-listbox
										v-if="!optionGroups.length"
										v-validate="'required'"
										class="col-sm-9"
										style="width: 100%"
										:options="[]"
										:reduce-display-property="(option) => option.text"
										:reduce-value-property="(option) => option.value"
										no-options-found-text="No data found"
										no-selected-options-found-text="No selected data found"
										name="partner"
										:state="validateState('partner')"
										data-vv-as="partner"	
									/>
								</div>
								<div class="row">
									<div class="col-md-3"></div>
									<div class="col">
										<small class="text-danger">
											{{ veeErrors.first('partner') }}
										</small>
									</div>
								</div>
								<div class="form-group row mt-3">
									<label class="col-sm-2">Choose region :<span class="text-danger"> *</span></label>
									<b-form-radio-group
										v-model="LocationType"
										v-validate="'required'"
										class="col-sm-9"
										description=""
										:options="optionRegion"
										name="region"
										:state="validateState('region')"
										data-vv-as="region"									
									>
										<br>
										<small class="text-danger">
											{{ veeErrors.first('region') }}
										</small>
									</b-form-radio-group>
								</div>
								<div class="form-group row mt-3">
									<label class="col-sm-2">Start Date :<span class="text-danger"> *</span></label>
									<b-form-group id="start_date" class="col-sm-3" description="">
										<b-form-input 
											id="start_date" 
											v-model="StartFrom" 
											v-validate="'required'" 
											name="startDate" 
											data-vv-as="Start Date"
											:state="validateState('startDate')" 
											type="date"
										></b-form-input>
										<label for=""></label>
										<b-form-invalid-feedback>
											{{ veeErrors.first('startDate') }}
										</b-form-invalid-feedback>
									</b-form-group>
								</div>
								<div class="form-group row mt-3">
									<label class="col-sm-2">End Date :<span class="text-danger"> *</span></label>
									<b-form-group id="end_date" class="col-sm-3" description="">
										<b-form-input 
											id="end_date" 
											v-model="StartTo" 
											v-validate="'required'" 
											name="endDate" 
											data-vv-as="End Date"
											:state="validateState('endDate')" 
											type="date"
										></b-form-input>
										<b-form-invalid-feedback>
											{{ veeErrors.first('endDate') }}
										</b-form-invalid-feedback>
									</b-form-group>
								</div>
								<div class="form-group row mt-3">
									<label class="col-sm-2">Status :<span class="text-danger"> *</span></label>
									<b-form-radio-group 
										id="status" 
										v-model="Status" 
										v-validate="'required'" 
										class="col-sm-9" 
										name="status"
										:state="validateState('status')"
										data-vv-as="status"
									>
										<b-form-radio v-model="Status" name="some-radios" value="ACTIVE">Active</b-form-radio>
										<b-form-radio v-model="Status" name="some-radios" value="INACTIVE">Inactive</b-form-radio>
										<br>
										<small class="text-danger">
											{{ veeErrors.first('status') }}
										</small>
									</b-form-radio-group>
								</div>
							</b-col>
						</b-row>
					</template>

					<template v-if="formRole.Type == 'SELF_ASSIGNMENT'">
						<b-row>
							<b-col>
								<div class="form-group row mt-3">
									<label class="col-sm-2">Start Date :<span class="text-danger"> *</span></label>
									<b-form-group id="start_date" class="col-sm-3" description="">
										<b-form-input 
											id="start_date" 
											v-model="StartFrom" 
											v-validate="'required'" 
											name="startDate" 
											data-vv-as="Start Date"
											:state="validateState('startDate')" 
											type="date"
										></b-form-input>
										<label for=""></label>
										<b-form-invalid-feedback>
											{{ veeErrors.first('startDate') }}
										</b-form-invalid-feedback>
									</b-form-group>
								</div>
								<div class="form-group row mt-3">
									<label class="col-sm-2">End Date :<span class="text-danger"> *</span></label>
									<b-form-group id="end_date" class="col-sm-3" description="">
										<b-form-input 
											id="end_date" 
											v-model="StartTo" 
											v-validate="'required'" 
											name="endDate" 
											data-vv-as="End Date"
											:state="validateState('endDate')"
											type="date"
										></b-form-input>
										<b-form-invalid-feedback>
											{{ veeErrors.first('endDate') }}
										</b-form-invalid-feedback>
									</b-form-group>
								</div>
								<div class="form-group row mt-3">
									<label class="col-sm-2">Status :<span class="text-danger"> *</span></label>
									<b-form-radio-group id="status" 
										v-model="Status" 
										v-validate="'required'" 
										class="col-sm-9" 
										name="status"
										:state="validateState('status')"
										data-vv-as="status"
									>
										<b-form-radio v-model="Status" name="some-radios" value="ACTIVE">Active</b-form-radio>
										<b-form-radio v-model="Status" name="some-radios" value="INACTIVE">Inactive</b-form-radio>
										<br>
										<small class="text-danger">
											{{ veeErrors.first('status') }}
										</small>
									</b-form-radio-group>
								</div>
							</b-col>
						</b-row>
					</template>
				</template>

				<!-- BULK -->
				<template v-if="selectedOptions == 2">
					<b-row>
						<b-col>
							<a
								href="https://koltiva-central.s3.ap-southeast-1.amazonaws.com/frontend/bulk-field-agent-upload-template/Field_Agent_Assignment_Upload_Template.xlsx"
							>	Download Format
							</a>
							<br><br>
							<b-file
								ref="fileupload"
								v-validate="'required'"
								size="sm"
								accept=".xlsx"
								:placeholder="`Choose a file ..`"
								:state="validateState('file')"
								name="file"
								@click.native="resetFile()"
							/>
							<small class="text-danger">
								{{ veeErrors.first("file") }}
							</small>
							<br>
						</b-col>
					</b-row>
				</template>
			</template>
			<template #footer>
				<template v-if="selectedOptions == 1"> 
					<div v-if="formRole.Type == 'SELF_ASSIGNMENT'">
						<ktv-button
							class="float-right"
							text="Add Farmer"
							:loading="loading"
							color="warning"
							icon="search"
							@click="openFormFarmer"
						/>
					<!-- <b-button
						variant="warning" 
						size="sm" 
						@click="openFormFarmer"
					>
						Nextxxx
					</b-button> -->
					</div>
					<div v-if="formRole.Type != 'SELF_ASSIGNMENT' && formRole.Type != '' && formRole.Type == 'PUBLIC'">
						<ktv-button v-if="act === 'edit'"
							class="float-right"
							text="Update"
							:loading="loading"
							color="primary"
							icon="save"
							@click="updateData('PUBLIC')"
						/>
						<ktv-button v-else
							class="float-right"
							text="Save"
							:loading="loading"
							color="primary"
							icon="save"
							@click="saveRole()"
						/>
					</div>
					<div v-if="formRole.Type != 'SELF_ASSIGNMENT' && formRole.Type != '' && formRole.Type == 'PRIVATE'">
						<ktv-button v-if="act === 'edit'"
							class="float-right"
							text="Update"
							:loading="loading"
							color="primary"
							icon="save"
							@click="updateData('PRIVATE')"
						/>
						<ktv-button v-else
							class="float-right"
							text="Save"
							:loading="loading"
							color="primary"
							icon="save"
							@click="saveRolePrivate()"
						/>
					</div>
					<ktv-button
						class="float-right"
						text="Cancel"
						:loading="loading"
						color="light"
						style="margin-right: 5px"
						@click="closeFormRole"
					/>
				</template> 
				
				<template v-if="selectedOptions == 2"> 
					<div v-if="selectedOptions == 2">
						<ktv-button
							class="float-right"
							text="Save"
							:loading="loading"
							color="primary"
							icon="save"
							@click="storeFile()"
						/>
					</div>
					<ktv-button
						class="float-right"
						text="Cancel"
						:loading="loading"
						color="light"
						style="margin-right: 5px"
						@click="closeFormRole"
					/>
				</template>
			</template>
		</ktv-modal>

		<b-modal id="formFarmer" size="xl" title="Choose Farmer" :scrollable="false" hide-header-close no-close-on-esc no-close-on-backdrop hide-footer @close="closeFormFarmer">
			<div class="modal-body">
				<FormFarmerAssignment 
					:data="dataSelfAss" 
					:farmer="[Farmer, act]" 
					@add-member="saveRoleSelfAssignment"
					@edit-member="updateFarmerAssignment"
				/>
			</div>
			<div class="modal-footer">				
				<ktv-button
					class="mt-3"
					text="Back"
					icon="none"
					color="warning"
					style="margin-left: 5px"
					@click="backToTypeModal"
				/>
				<ktv-button
					class="mt-3"
					text="Close"
					icon="close"
					color="light"
					style="margin-left: 5px"
					@click="closeFarmerAssignment"
				/>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import {
		KtvButton,
		KtvTable,
		KtvHeadForm,
		KtvModal,
	} from "@/components"
	import FormFarmerAssignment from "@/views/modules/field_agent/modal/FormFarmerAssignment"
	import { mapActions, mapGetters } from "vuex"

	export default {
		metaInfo: {
			title: "FormAgent",
		},
		components: {
			// eslint-disable-next-line vue/no-unused-components
			KtvButton,
			// eslint-disable-next-line vue/no-unused-components
			KtvTable,
			// eslint-disable-next-line vue/no-unused-components
			KtvHeadForm,
			// eslint-disable-next-line vue/no-unused-components
			KtvModal,
			// eslint-disable-next-line vue/no-unused-components
			FormFarmerAssignment,
		},
		data() {
			return {
				/* eslint-disable global-require */
				iconedit: require("@/assets/images/icon/iconedit.png"),
				icondelete: require("@/assets/images/icon/icondelete.png"),
				iconview: require("@/assets/images/icon/iconview.png"),
				icondropdown: require("@/assets/images/icon/icondropdown.png"),
				iconNext: require("@/assets/images/icon/iconnext.png"),
				isLoading: false,
				modeTable: "remote",
				rows: [],
				totalRecords: 0,
				serverParams: {
					columnFilters: {
						id: "",
					},
					sort: [{
						field: "",
						type: "ASC",
					},
					],
					currentPage: 1,
					rowPerpage: 10,
				},
				selected: '1',
				options: [

					{ text: 'Active', value: '1' },

					{ text: 'inactive', value: '2' },

				],
				selected2: '1',
				options2: [

					{ text: 'Male', value: 'Male' },

					{ text: 'Female', value: 'Female' },

				],
				selected3: '1',
				options3: [

					{ text: 'Male', value: '1' },

					{ text: 'Female', value: '2' },

				],
				loading: false,
				mainProps: { blank: true, blankColor: '#E5E7EB', width: 100, height: 100, class: 'm1' },
				formRoleModal: false,
				formRole: {
					Type: "",
				},
				optionType: [
					{
						text: "PUBLIC", value: "PUBLIC"
					},
					{
						text: "PRIVATE", value: "PRIVATE"
					},
					{
						text: "Self Assignment", value: "SELF_ASSIGNMENT"
					},
				],
				optionRegion: [
					{
						text: "Village", value: "VILLAGE"
					},
					{
						text: "Subdistrict", value: "SUBDISTRICT"
					},
					{
						text: "District", value: "DISTRICT"
					},
					{
						text: "Province", value: "PROVINCE"
					},
					{
						text: "Country", value: "COUNTRY"
					},
				],
				optionStatus: [
					{
						text: "Active", value: "ACTIVE"
					},
					{
						text: "Inactive", value: "INACTIVE"
					},
				],
				formFarmer: false,
				status_type:"",
				optionGroups:[],
				loadingGroups: false,
				dataSelfAss:{},
				// region:"",
				status_farmer:"",
				FCFieldAgentMappingID: null,
				loadingDetail: false,

				EntityId:"",
				StartFrom:"",
				StartTo:"",
				Status:"",
				Type:"",
				Partner:[],
				Farmer:[],
				LocationType:"",
				detailItems:{},
				bankData:[],

				action : this.$route.query.act,
				eID:"",
				act:"",

				selectedOptions: ""

			}
		},
		computed: {
			...mapGetters({
				opsiDisplay: "FIELDAGENT/getOpsiDisplay",
				entityID: "FIELDAGENT/getEntityID",
				optionPartnerFA: "FIELDAGENT/getPartnerFA",
			})
		},
		created(){},
		async mounted() {
			if(this.entityID === null || this.entityID === ""){
				this.$router.push('/field_agent/list'); return
			}
			await this.loadDetailItem()
			await this.FetchData()
			await this.getAllPartner()

		},
		methods: {
			storeFile() {
				if(this.$refs.fileupload.files.length === 0){
					this.$swal({
						type: 'error',
						title: 'File cannot be empty',
					})
					return
				}
				this.$swal.fire({
					title: 'Submiting...',
					allowOutsideClick: false,
					onBeforeOpen: () => {
						this.$swal.showLoading()
						let formData = new FormData()
						formData.append('file_data', this.$refs.fileupload.files[0]);
						this.$http
							.Post(formData, this.$urlApi.fieldAgent.uploadFarmerAssignment)
							.then(async (response) => {
								if(response.success === false){
									await this.$swal('Error!', response.error_message, 'error'); return
								}

								if(response.success === true){
									await this.$swal(
										'Saved!',
										'Successfully submit.',
										'success'
									);
									this.closeFormRole()
								}

								this.FetchData()
							})
							.catch(() => {
								this.$swal({
									type: 'error',
									title: 'Upload Failed',
								})
							});
					}
				})
			},
			resetFile() {
				this.$refs.fileupload.reset();
			},
			updateFarmerAssignment(checkedData) {
				this.$validator.validateAll().then(result => {
					if (!result) {
						return;
					}
					this.$swal({
						title: 'Are you sure?',
						text: "",
						type: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Yes'
					}).then((response) => {
						if (response.value) {
							this.$swal.fire({
								title: 'Loading data...',
								allowOutsideClick: false,
								onBeforeOpen: () => {
									this.$swal.showLoading()
									let payload = { 
										FCFieldAgentMappingID : checkedData[1].FCFieldAgentMappingID,
										EntityId : checkedData[1].EntityId,
										StartFrom : checkedData[1].StartFrom,
										StartTo : checkedData[1].StartTo,
										Status : checkedData[1].Status,
										Type : checkedData[1].Type,								
										Farmer : checkedData[0],																
																		
									}
									this.$http.
										Post(payload, this.$urlApi.fieldAgent.update)
										.then(async (r) => {
											if(r.success === true){
												await this.$swal('Success!', 'Successfully Update Data.', 'success');
												this.closeFormFarmer()
												this.FetchData()
											}
											if(r.success === false){
												await this.$swal('Error!', r.error_message, 'error');
											}	
										})
										.catch(async (error) => {
											await this.$swal("Error!", error , "error")
										}).finally(() => this.$swal.close())
								}
							})
						}
					});
				});
				
			},
			updateData() {
				this.$validator.validateAll().then(result => {
					if (!result) {
						return;
					}
					this.$swal({
						title: 'Are you sure?',
						text: "",
						type: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Yes'
					}).then((response) => {
						if (response.value) {
							this.$swal.fire({
								title: 'Loading data...',
								allowOutsideClick: false,
								onBeforeOpen: () => {
									this.$swal.showLoading()
									let payload = { 
										EntityId : this.eID,
										StartFrom : this.StartFrom,
										StartTo : this.StartTo,
										Status : this.Status,
										Type : this.formRole.Type,									
										Partner : this.Partner,									
										LocationType : this.LocationType,									
										FCFieldAgentMappingID : this.FCFieldAgentMappingID,									
									}
									this.$http.
										Post(payload, this.$urlApi.fieldAgent.update)
										.then(async (r) => {
											if(r.success === true){
												await this.$swal('Success!', 'Successfully Update Data.', 'success');
												this.closeFormRole()
												this.FetchData()
											}
											if(r.success === false){
												await this.$swal('Error!', r.error_message, 'error');
											}	
										})
										.catch(async (error) => {
											await this.$swal("Error!", error , "error")
										}).finally(() => this.$swal.close())
								}
							})
						}
					});
				});
				
			},
			findData(value, act) {
				this.act = act

				this.formRole.Type = value.Type;
				this.formRoleModal = true

				let a = this.rows.find(o => o.FCFieldAgentMappingID === value.FCFieldAgentMappingID);
				this.eID = a.EntityID;
				this.LocationType = a.LocationType;
				this.StartFrom = a.StartFrom;
				this.StartTo = a.StartTo;
				this.Status = a.Status;
				this.FCFieldAgentMappingID = a.FCFieldAgentMappingID				
				this.Partner = a.Partner !== null ? a.Partner.map(o => o.value) : ""
				this.Farmer = a.Farmer !== null ? a.Farmer.map(o => o.farmerid) : ""
				// this.Farmer = ['731104806']
				// this.Farmer = ['731104806', 'F73050010002']
				
				// this.commodity_name = a.objectData.commodity_name;
			},
			loadDetailItem() {
				this.$swal.fire({
					title: 'Loading...',
					allowOutsideClick: false,
					onBeforeOpen: () => {
						this.$swal.showLoading()
						this.$http
							.Get({'entityId': this.entityID},this.$urlApi.fieldAgent.getDetail)
							.then((response) => {
								if (response.success) {
									this.detailItems = response.results.personal_data;
									this.bankData = response.results.bank_data
								} else {
									this.$swal("Error!", response.error_massage, "error")
								}
							})
							.catch((error) => {
								this.$swal("Error!", error.response, "error")
							}).finally(() => this.$swal.close())
					}
				})
			},
			getAllPartner() {
				this.$http
					.Get({}, this.$urlApi.fieldAgent.getPartnerFA)
					.then((response) => {
						if (response.success) {
							this.optionGroups = response.results
						} else {
							this.$swal("Error!", response.error_massage, "error")
						}
					})
					.catch((error) => {
						this.$swal("Error!", error.response, "error")
					})
			},
			...mapActions({
				clearData: "FIELDAGENT/clearData",
				actGetDetail: "FIELDAGENT/actGetDetail",
				actGetPartnerFA: "FIELDAGENT/actGetPartnerFA",
				actPostData: "FIELDAGENT/actPostData",
			}),
			saveRoleSelfAssignment(checkedData) {
				this.$validator.validateAll().then(result => {
					if (!result) {
						return;
					}
					this.$swal({
						title: 'Are you sure?',
						text: "",
						type: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Yes'
					}).then((response) => {
						if (response.value) {
							this.$swal.fire({
								title: 'Loading data...',
								allowOutsideClick: false,
								onBeforeOpen: () => {
									this.$swal.showLoading()
									let payload = { 
										EntityId : this.entityID,
										StartFrom : checkedData[1].StartFrom,
										StartTo : checkedData[1].StartTo,
										Status : checkedData[1].Status,
										Type : checkedData[1].Type,															
										Farmer : checkedData[0],															
									}
									this.$http.
										Post(payload, this.$urlApi.fieldAgent.createRole)
										.then(async (r) => {
											this.FetchData()
											if(r.success === true){
												await this.$swal('Success!', 'Successfully.', 'success');
												this.closeFormFarmer()											
											}
											if(r.success === false){
												await this.$swal('Error!', r.error_message, 'error');
											}	
										})
										.catch(async (error) => {
											await this.$swal("Error!", error , "error")
										}).finally(() => this.$swal.close())
								}
							})
						}
					});
				});
				
			},
			saveRolePrivate() {
				if(this.StartTo < this.StartFrom){
					this.$swal("Info!", "End Date cannot be less than Start Date" , "warning"); return
				}
				this.$validator.validateAll().then(result => {
					if (!result) {
						return;
					}
					this.$swal({
						title: 'Are you sure?',
						text: "",
						type: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Yes'
					}).then((response) => {
						if (response.value) {
							this.$swal.fire({
								title: 'Loading data...',
								allowOutsideClick: false,
								onBeforeOpen: () => {
									this.$swal.showLoading()
									let payload = { 
										EntityId : this.entityID,
										StartFrom : this.StartFrom,
										StartTo : this.StartTo,
										Status : this.Status,
										Type : this.formRole.Type,									
										Partner : this.Partner,											
										LocationType : this.LocationType,									
									}
									this.$http.
										Post(payload, this.$urlApi.fieldAgent.createRole)
										.then(async (r) => {
											this.FetchData()
											if(r.success === true){
												await this.$swal('Success!', 'Successfully.', 'success');
												this.closeFormRole()												
											}
											if(r.success === false){
												await this.$swal('Error!', r.error_message, 'error');
											}	
										})
										.catch(async (error) => {
											await this.$swal("Error!", error , "error")
										}).finally(() => this.$swal.close())
								}
							})
						}
					});
				});
				
			},
			async FetchData() {
				if (this.opsiDisplay === "View" || this.opsiDisplay === "Update") {
					const res = await this.actGetDetail()
					this.rows = res.results
					this.loading = false
				}
			},
			showLoadingMessage(message) {
				this.$swal.fire({
					title: `${message}`,
					animation: true,
					allowOutsideClick: false,
					onBeforeOpen: () => {
						this.$swal.showLoading()
					},
				})
			},
			saveRole() {
				if(this.StartTo < this.StartFrom){
					this.$swal("Info!", "End Date cannot be less than Start Date" , "warning"); return
				}
				this.$validator.validateAll().then(result => {
					if (!result) {
						return;
					}
					this.$swal({
						title: 'Are you sure?',
						text: "",
						type: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Yes'
					}).then((response) => {
						if (response.value) {
							this.$swal.fire({
								title: 'Loading data...',
								allowOutsideClick: false,
								onBeforeOpen: () => {
									this.$swal.showLoading()
									let dataRole= {
										EntityId: this.entityID,
										Type: this.formRole.Type,
										LocationType: this.LocationType,
										StartFrom: this.StartFrom,
										StartTo: this.StartTo,
										Status: this.Status,
									}
									return this.actPostData(dataRole).then((results) => {
										return results;
									})
										.then(async (r) => {
											this.FetchData()
											if(r.success === true){
												await this.$swal('Success!', 'Successfully.', 'success');
												this.closeFormRole()
											}
											if(r.success === false){
												await this.$swal('Error!', r.error_message, 'error');
											}	
										})
										.catch(async (error) => {
											await this.$swal("Error!", error , "error")
										}).finally(() => this.$swal.close())
								}
							})
						}
					});
				});
				
			},
						
			openForm() {
			},
			backToList() {
				this.$router.back()
			},
			closeFormRole() {
				// public
				this.formRoleModal = false
				this.formRole.Type = ""

				// private
				this.StartFrom = ""
				this.StartTo = ""
				this.Status = ""
				this.Partner = []
				this.LocationType = ""
				this.FCFieldAgentMappingID = null

				this.selectedOptions = ""
			},
			openFormRole(x) {
				this.act = x
				this.formRoleModal = true
			},
			openFormFarmer() {
				if(this.StartTo < this.StartFrom){
					this.$swal("Info!", "End Date cannot be less than Start Date" , "warning"); return
				}
				this.$validator.validateAll().then(result => {
					if (!result) {
						return;
					}
					this.$swal({
						title: 'Are you sure?',
						text: "",
						type: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Yes'
					}).then((response) => {
						if (response.value) {
							this.dataSelfAss = {
								EntityId : this.entityID,
								StartFrom : this.StartFrom,
								StartTo : this.StartTo,
								Status : this.Status,
								FCFieldAgentMappingID : this.FCFieldAgentMappingID,
								Type : this.formRole.Type,
							};
							this.formRoleModal = false;
							this.$bvModal.show('formFarmer')
						}
					});
				});
			},
			closeFormFarmer() {
				this.$bvModal.hide('formFarmer')
				this.$validator.validateAll();

			},
			backToTypeModal() {
				this.$bvModal.hide('formFarmer')
				this.formRoleModal = true
			},
			closeFarmerAssignment() {
				this.$bvModal.hide('formFarmer')
			},
		}
	}
</script>

<style scoped>
.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
    max-height: calc(100vh - 210px);
    overflow-y: auto;
}
.dropdownitem-action:hover a,
.dropdownitem-action:focus a {
	background-color: #CAEAFF !important;
}
</style>
<template>
	<div class="main-content">
		<!-- <label for="">FARMER ------ {{ farmer }}</label><br>
		<label for="">DATA ------ {{ data }}</label>
		<label for="">checkboxFarmer ------ {{ checkboxFarmer }}</label> -->
		<Search 
			:show-country="true" 
			:show-province="true" 
			:show-district="true" 
			:show-input-type="true" 
			:placeholder="'Search By ID & Name'"
			@change="onSearchFilter"
		/>
		
		<table class="table table-hover ">
			<thead class="thead">
				<tr class="">
					<th>Action</th>
					<th>ID</th>
					<th>Name</th>
					<th>Gender</th>
					<th>Country</th>
					<th>Province</th>
					<th>District</th>
					<th>Sub District</th>
					<th>Village</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(a, key) in allFarmers" :key="key">
					<td>
						<div>
							<b-form-checkbox  
								:id="a.Farmerid"
								v-model="checkboxFarmer"
								style="margin-top:-19%"
								name="checkbox-1"
								:value="a"
								@change="doChecked(checkboxFarmer)"
							>
							</b-form-checkbox>
						</div>
					</td>
					<td>
						{{ a.Farmerid }}
					</td>
					<td>{{ a.PersonName }}</td>
					<td>{{ a.Gender }}</td>
					<td>{{ a.CountryName }}</td>
					<td>{{ a.ProvinceName }}</td>
					<td>{{ a.DistrictName }}</td>
					<td>{{ a.SubDistrictName }}</td>
					<td>{{ a.VillageName }}</td>
				</tr>
			</tbody>
		</table>			
		<div>
			<paginationBarCustom 
				:state="paginationData" 
				@paginate="onPageChange()"
			/>
		</div>
		<div>
			<list-farmer-assignment 
				:selected-data="checkboxFarmer"
			/>
		</div>
		<div>
			<b-button v-if="farmer[1] === 'add'"
				variant="primary" 
				size="sm" 
				@click="addMember()"
			>
				Add Member
			</b-button>
			<b-button v-if="farmer[1] === 'edit'"
				variant="primary" 
				size="sm" 
				@click="updateData()"
			>
				Update Data
			</b-button>
		</div>
	</div>
</template>

<script>
	import ListFarmerAssignment from "@/views/modules/field_agent/modal/ListFarmerAssignment"
	import paginationBarCustom from "@/components/paginationBarCustom"
	import Search from "@/components/searchCustom"

	export default {
		metaInfo: {
			title: "Form Farmer Assigment",
			name: 'InputFarmer'
		},
		components: {
			ListFarmerAssignment,
			paginationBarCustom,
			Search
		},
		// eslint-disable-next-line vue/require-prop-types
		props:["data", "farmer"],
		data() {
			return {
				/* eslint-disable global-require */
				iconedit: require("@/assets/images/icon/iconedit.png"),
				icondelete: require("@/assets/images/icon/icondelete.png"),
				iconview: require("@/assets/images/icon/iconview.png"),
				icondropdown: require("@/assets/images/icon/icondropdown.png"),
				/* eslint-disable global-require */

				allFarmers:[],

				selectedData:[],

				serverParams: {
					columnFilters : {
						CountryID: "",
						ProvinceID: "",
						DistrictID: "",
						NameOrID: "",
					},					
					sort: [
						{
							field: "",
							type: "ASC",
						},
					],
					currentPage: 1,
					rowPerpage: 10,
				},

				paginationData: {
					pagination: {
						current_page: 1
					},
				},
				checkboxFarmer:[],
				checkedData:[],
			}
		},
		computed: {
			// sortedCategories() {
			// 	const uniq = this.selectedData.reduce((p,c) => {
			// 		p[c.Farmerid] = c
			// 		return p
			// 	}, {})
			// 	return Object.values(uniq).sort((a,b) => a.Farmerid.localeCompare(b.Farmerid))
			// }

		},
		created(){
			this.getallFarmers()
		},
		mounted() {
			this.getSelectedData()
		},
		methods: {
			getSelectedData() {
				this.farmer[0].forEach(async (eid) => {
					let params = {
						columnFilters : {
							NameOrID: eid,
						},					
						sort: [
							{
								field: "",
								type: "ASC",
							},
						],
						currentPage: 1,
						rowPerpage: 10,
					};
					this.$http
						.Post(params, this.$urlApi.fieldAgent.getFarmer)
						.then((r) => {
							let farmer = r.results.data;

							farmer.forEach((x) => {
								this.checkboxFarmer.push(x)
							})
						})
						.catch((error) => {
							this.$swal("Error!", error.response, "error")
						})
				});							
			},
			addMember(){
				if(this.checkedData.length === 0){
					this.$swal("Info!", "You haven't selected a farmer" , "warning"); return
				}
				// eslint-disable-next-line vue/require-explicit-emits
				this.$emit('add-member', [this.checkedData, this.data])
			},
			updateData(){
				if(this.checkedData.length === 0){
					this.$swal("Info!", "You haven't selected a farmer" , "warning"); return
				}
				// eslint-disable-next-line vue/require-explicit-emits
				this.$emit('edit-member', [this.checkedData, this.data])
			},
			async doChecked(x){
				try {
					this.checkedData = []
					x.forEach(async (v) => {
						await this.checkedData.push(v.EntityID);
					});	

				// eslint-disable-next-line no-empty
				} catch (error) {}
			},
			updateParams(newProps) {
				this.serverParams = Object.assign({}, this.serverParams, newProps)
			},
			onSearchFilter(value) {				
				this.updateParams({
					columnFilters :{
						CountryID: value.Country.id,
						ProvinceID: value.Province.id,
						DistrictID: value.District.id,
						NameOrID: value.Name,
					}					
				})
				this.getallFarmers()
			},

			onPageChange() {
				this.updateParams({ currentPage: this.paginationData.pagination.current_page })
				this.getallFarmers()
			},
			getallFarmers() {
				this.$swal.fire({
					title: 'Loading...',
					allowOutsideClick: false,
					onBeforeOpen: () => {
						this.$swal.showLoading()
						this.$http
							.Post(this.serverParams, this.$urlApi.fieldAgent.getFarmer)
							.then((r) => {
								this.allFarmers = r.results.data;
								this.paginationData.pagination = r.results
							})
							.catch((error) => {
								this.$swal("Error!", error.response, "error")
							}).finally(() => this.$swal.close())
					}
				})		
				this.updateParams({ currentPage: 1 })		
			},

		}
	}
</script>

<style scoped>

</style>
<template>
	<div class="main-content list-farmer">
		<hr>
		<h5>List Farmer Assignment</h5>
		<table class="table table-hover ">
			<thead class="thead">
				<tr class="">
					<th>No.</th>
					<th>ID</th>
					<th>Name</th>
					<th>Gender</th>
					<th>Country</th>
					<th>Province</th>
					<th>District</th>
					<th>Sub District</th>
					<th>Village</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(a, key) in selectedData" :key="key">
					<td>{{ key + 1 }}</td>
					<td>{{ a.Farmerid }}</td>
					<td>{{ a.PersonName }}</td>
					<td>{{ a.Gender }}</td>
					<td>{{ a.CountryName }}</td>
					<td>{{ a.ProvinceName }}</td>
					<td>{{ a.DistrictName }}</td>
					<td>{{ a.SubDistrictName }}</td>
					<td>{{ a.VillageName }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
	import {
		// KtvButton,
		// eslint-disable-next-line no-unused-vars
		KtvTable,
	} from "@/components"

	export default {
		metaInfo: {
			title: "ListFarmerAssignment",
			name: 'ListFarmerAssignment'
		},
		components: {
			
		},
		// eslint-disable-next-line vue/require-prop-types
		props:["selectedData"],
		data() {
			return {}
		},
		computed: {

		},
		watch: {
		},
		created(){

		},
		methods: {},
	}
</script>

<style scoped>
.list-farmer{
	margin-top: 20px;
}

</style>